<template>
  <div>
    <div class="search">
      <div class="title">核销点列表</div>
      <hr />
      <el-form :inline="true" class="top">
      
        <el-form-item>
          <!-- <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button type="primary" @click="add">添加</el-button>
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"

      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="packRedeemName" label="核销点名称" align="center"> </el-table-column>
    
      <el-table-column prop="packName" label="套餐名称" align="center">
      </el-table-column>
      <el-table-column  align="center" label="核销点名称">
        <template slot-scope="{row}">
            <span v-for="(item,index) in row.redeemNameMaps" :key="index">
                {{ item.redeemName }}
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="时间" width="180" align="center">
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
           
            <el-button type="danger"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
           
        </template>
        </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="添加/修改"
      :visible.sync="dialogVisible"
      width="30%"
     
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="核销点名称:" prop="packRedeemName">
          <el-input v-model="addList.packRedeemName"></el-input>
        </el-form-item>
        <el-form-item label="选择核销点:" prop="redeemIds">
            <el-button type="primary" @click="dialogVisible2 = true">选择核销点</el-button>

        </el-form-item>
     
        <div>已选核销点:</div>
        <div style="padding:5px 0;display:flex;align-items: center;" v-for="(item,index) in redeemIdsArr" :key="item.redeemId">
            <span style="margin-right:3px">{{ item.redeemName }}</span>
            <el-link  type="danger" @click="removeItem(index)">删除</el-link>
        </div>
      </el-form>
    
      <el-dialog append-to-body :visible.sync="dialogVisible2">
        <writeoff :status="2" ref="writeRef" @submit="packSubmit($event,1)"></writeoff>
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="packSubmit">确 定</el-button>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { foodPackListWriteList,foodPackListWriteListAdd,foodPackListWriteListDel } from "../../api/food.js";
import  writeoff  from "../administrator/writeoff/writeoff.vue";
export default {
    components: {
        writeoff
    },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
       
      },
      addList: {
        packRedeemId: 0,
        packId: 0,
        packRedeemName: "",
        redeemIds: "",
      },
      redeemIdsArr: [],
      addRules: {
        packRedeemName: [
          { required: true, message: "请输入核销点名称", trigger: ["blur", "change"] },
        ],
        redeemIds: [
          { required: true, message: "请选择核销点", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.addList.packId = this.$route.query.packId;
    this.params.packId = this.$route.query.packId;
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
    //   this.params.beginTime = this.date ? this.date[0] : "";
    //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await foodPackListWriteList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    add() {
        this.addList.packRedeemId = 0;
        this.addList.packRedeemName = "";
        this.redeemIdsArr = [];
        this.addList.redeemIds = ''
      this.dialogVisible = true;
    },
    packSubmit(e,status=0){
        console.log(e)
        let params = []
        if(status == 1){
            // 点击单选添加
          params.push(e);
        }else{
           params = this.$refs.writeRef.packSubmit();
           
        }
    //    let nArr =  Array.from(new Set([...params,...this.redeemIdsArr]))
    console.log(params,'params')
    let nArr = [...params,...this.redeemIdsArr]
    let map = new Map();
    // 数组去重
     this.redeemIdsArr =  nArr.filter((item)=>  !map.has(item.redeemId) && map.set(item.redeemId,item.redeemId)) 
      
       
       console.log(this.redeemIdsArr,'nArr')
       console.log(map,'map')
       this.dialogVisible2 = false;
       
    },
    submit() {
        this.addList.redeemIds = this.redeemIdsArr.map((item)=>item.redeemId).join(',')
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          
          const { data } = await foodPackListWriteListAdd(this.addList);
          if (data.code == 0) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        } else {
          return false;
        }
      });
    },
    edit(row){
       
        this.addList.packRedeemId = row.packRedeemId;
        this.addList.packRedeemName = row.packRedeemName;
        this.addList.redeemIds = row.redeem_ids.join(',');
        this.redeemIdsArr = row.redeemNameMaps
        this.dialogVisible  = true;
    },
    removeItem(index){
        this.redeemIdsArr.splice(index,1)
    },
    remove(row){
        // 是否删除
        this.$confirm('是否删除该核销点?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const { data } = await foodPackListWriteListDel({packRedeemId:row.packRedeemId});
            if (data.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message({
                message: data.msg,
                type: "error",
              });
            }
          }).catch(() => {
                 
          });
    }
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
